<template>
  <div class="global-box penlin">
    <div class="top">
      <div class="back-l"><span>&lt;</span>&nbsp;环控1数据</div>
      <el-form :inline="true" :model="form" class="global-form global-form-inline">
        <el-form-item>
          <el-date-picker v-model="form.date" type="daterange" range-separator="至" start-placeholder="打卡时间"
            end-placeholder="打卡时间" />
        </el-form-item>

        <el-form-item>
          <span class="btn-reset" @click="onReset" style="margin-right: 10px">重置</span>
          <span class="btn-search" @click="onSubmit">查询</span>
        </el-form-item>
      </el-form>
    </div>

    <div class="cards">
      <div class="card">
        <div class="value">29</div>
        <div class="label">喷淋次数</div>
      </div>
      <div class="card">
        <div class="value">24</div>
        <div class="label">喷淋时长</div>
      </div>
      <div class="card">
        <div class="value">29<span class="unit">℃</span></div>
        <div class="label">当前温度</div>
      </div>
      <div class="card">
        <div class="value">29<span class="unit">%RH</span></div>
        <div class="label">当前湿度</div>
      </div>
      <div class="card">
        <div class="value">29</div>
        <div class="label">THI</div>
      </div>
      <div class="card">
        <div class="value">2</div>
        <div class="label">当前喷淋级别</div>
      </div>
    </div>

    <div class="data_box">
      <div class="title">喷淋数据</div>
      <div class="tabs">
        <div @click="() => handleClick('1')" class="tab-item" :class="{ active: activeName == '1' }">
          <span>图表形式查看</span>
        </div>
        <div @click="() => handleClick('2')" class="tab-item" :class="{ active: activeName == '2' }">
          <span>数据形式查看</span>
        </div>
      </div>

      <!-- <div>
        <el-radio-group v-model="activeName" size="large">
          <el-radio-button label="1"></el-radio-button>
          <el-radio-button label="2"></el-radio-button>
        </el-radio-group>
      </div> -->

      <div class="data">

        <div v-if="activeName == '1'" class="chart">
          <div id="chart-con" class="chart">
          </div>
        </div>

        <el-table v-else border :data="tableData" class="my-table">
          <el-table-column prop="startTime" label="日期" :formatter="formatterTime"></el-table-column>
          <el-table-column prop="className" label="温度"> </el-table-column>
          <el-table-column prop="className" label="喷淋等级"> </el-table-column>
          <el-table-column prop="className" label="喷淋时长"> </el-table-column>
        </el-table>
      </div>


    </div>
  </div>
</template>

<script>
import { data } from './data'
import dayjs from 'dayjs';
import * as echarts from "echarts";

const timeData = Array.from({ length: 7 }, (v, k) => {
  return dayjs().subtract(6 - k, 'day').format('YYYY-MM-DD')
});


export default {
  data() {
    return {
      activeName: '1',
      form: {},
      tableData: data.data.list
    }
  },
  mounted() {
    this.setChart();
  },
  beforeUnmount() {
    this.chart && this.chart.dispose();
  },
  methods: {
    handleClick(name) {
      this.activeName = name;
    }, setChart(data) {
      // let data = [
      //   {
      //     name: "上限",
      //     time: ["00:00", "00:01", "00:02", "00:03"],
      //     value: [1, 2, 3, 4],
      //   },
      // ];
      this.chart && this.chart.dispose();

      let dom = document.getElementById("chart-con");
      let chart = echarts.init(dom);
      let option;
      option = {
        title: {
          text: '',
          textStyle: {
            color: "#fff",

          }
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          icon: "circle",
          itemWidth: 15,
          itemHeight: 15,
          textStyle: {
            color: "#fff",
          },
          data: ['温度', '喷淋时长']
        },
        grid: {
          left: 20,
          bottom: 40,
          right: 60,
          top: 60,
          containLabel: true
        },
        tooltip: {
          confine: true,
          // trigger: "axis",
          // axisPointer: {
          //   type: "cross",
          //   crossStyle: {
          //     color: "#31c4f6",
          //   },
          // },
        },
        xAxis: {
          type: 'category',
          axisPointer: {
            type: "shadow",
          },
          boundaryGap: false,
          axisLabel: {
            color: "#fff",
          },
          axisLine: {
            lineStyle: {
              color: "#fff",
            },
          },
          data: timeData
        },
        yAxis: [
          {
            scale: true,
            nameLocation: 'end',
            axisLine: {
              show: true,
              symbol: ['none', 'arrow'],
              symbolOffset: 40,
              lineStyle: {
                color: "#fff",
                shadowOffsetY: -40,
                shadowColor: "#fff",
              },
            },
            type: "value",
            name: "时长",
            nameGap: 60,
            nameTextStyle: {
              color: "#C5DBF4",
              verticalAlign: "top",
              fontSize: 14,
            },
            // splitLine: {
            //   show: true,
            //   lineStyle: {
            //     color: "#28478c",
            //     type: "dashed",
            //   },
            // },
            axisLabel: {
              formatter: "{value}",
              color: "#C5DBF4",
            },
          },
        ],
        series: [
          {
            name: '温度',
            type: 'line',
            symbol: 'circle',
            // symbolSize: '15',
            data: [25, 22, 21, 23, 29, 23, 26],
            areaStyle: {}
          },
          {
            name: '喷淋时长',
            type: 'line',
            symbol: 'circle',
            // symbolSize: '15',
            data: [12, 14, 11, 10, 10, 13, 10],
          },
        ]
      };
      chart.setOption(option);
      this.chart = chart;
    },
  }
}
</script>
<style scoped lang="less" src="./style.less"></style>